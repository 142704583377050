<template>
    <h3 class="text-muted font-italic text-center">
        <i class="fa fa-check mr-2"/>
        <span v-html="$t('NoActionExpected.noActionExpected')"/>
    </h3>
</template>

<script>
export default { name: "NoActionExpected" };
</script>

<style scoped>

</style>