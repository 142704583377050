var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"register-panel"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"register-email"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('RegisterPanel.email'))}}),_c('RedAsterisk',{staticClass:"ml-1"})],1),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend",class:classes},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-at"})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credentials.email),expression:"credentials.email"}],ref:"registerEmailInput",staticClass:"form-control",class:classes,attrs:{"id":"register-email","maxlength":"50","type":"email","placeholder":_vm.$t('RegisterPanel.required'),"required":"","disabled":_vm.loading},domProps:{"value":(_vm.credentials.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.credentials, "email", $event.target.value)}}})]),_c('div',{staticClass:"input-error",domProps:{"innerHTML":_vm._s(errors[0])}})]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"register-password"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('RegisterPanel.password'))}}),_c('RedAsterisk',{staticClass:"ml-1"})],1),_c('ValidationProvider',{attrs:{"rules":"required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend",class:classes},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-lock"})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.credentials.password),expression:"credentials.password"}],staticClass:"form-control",class:classes,attrs:{"id":"register-password","type":"password","placeholder":_vm.$t('RegisterPanel.required'),"required":"","minlength":"5","maxlength":"50","disabled":_vm.loading},domProps:{"value":(_vm.credentials.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.credentials, "password", $event.target.value)}}})]),_c('div',{staticClass:"input-error",domProps:{"innerHTML":_vm._s(errors[0])}})]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"register-confirm-password"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('RegisterPanel.confirmPassword'))}}),_c('RedAsterisk',{staticClass:"ml-1"})],1),_c('ValidationProvider',{attrs:{"rules":"confirm_password:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend",class:classes},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-lock"})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",class:classes,attrs:{"id":"register-confirm-password","type":"password","placeholder":_vm.$t('RegisterPanel.required'),"required":"","minlength":"5","maxlength":"50","disabled":_vm.loading},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}})]),_c('div',{staticClass:"input-error",domProps:{"innerHTML":_vm._s(errors[0])}})]}}])})],1)])]),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-10 col-lg-10"},[_c('span',{attrs:{"id":"register-data-disclaimer-text"},domProps:{"innerHTML":_vm._s(_vm.$t('RegisterPanel.userDataOnlyForAccount'))}})])]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12 text-center"},[_c('a',{attrs:{"href":"#"},domProps:{"innerHTML":_vm._s(_vm.$t('RegisterPanel.IAlreadyHaveAnAccount'))},on:{"click":function($event){$event.preventDefault();return _vm.$emit('open-login-tab')}}})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-2 col-lg-1 d-flex align-items-center"},[_c('i',{staticClass:"fa fa-info-circle fa-2x"})])}]

export { render, staticRenderFns }