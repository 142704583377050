import { render, staticRenderFns } from "./GameLobbyTutorial.vue?vue&type=template&id=a479499c&scoped=true&"
import script from "./GameLobbyTutorial.vue?vue&type=script&lang=js&"
export * from "./GameLobbyTutorial.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a479499c",
  null
  
)

export default component.exports