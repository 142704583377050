<template>
    <div id="spinning-loader" class="loader spinning-loader">
        <div>
            <div>
                <div>
                    <div>
                        <div>
                            <div/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default { name: "SpinningLoader" };
</script>

<style scoped>

</style>