<template>
    <span class="asterisk">
        *
    </span>
</template>

<script>
export default { name: "RedAsterisk" };
</script>

<style scoped>
    .asterisk {
        color: red;
    }
</style>