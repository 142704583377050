import { render, staticRenderFns } from "./GamePlayersPosition.vue?vue&type=template&id=2accbc2f&scoped=true&"
import script from "./GamePlayersPosition.vue?vue&type=script&lang=js&"
export * from "./GamePlayersPosition.vue?vue&type=script&lang=js&"
import style0 from "./GamePlayersPosition.vue?vue&type=style&index=0&id=2accbc2f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2accbc2f",
  null
  
)

export default component.exports